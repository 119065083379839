<template>
  <div>
    <div v-if="displayLoading">
      <Loading />
    </div>

    <div class="page">
      <div class="d-flex invoice-tab mb-3">
        <router-link
          :to="{ name: 'EinvoiceQueryByCarrier' }"
          class="invoice-tab--item"
          >手動輸入</router-link
        >
        <router-link :to="{ name: 'EinvoiceScan' }" class="invoice-tab--item"
          >上傳照片</router-link
        >
      </div>

      <p>result : {{ result }}</p>
      <p>error : {{ error }}</p>
    </div>

    <div class="page__button">
      <SharedButton class="s-btn-bg-primary" @click="openQRCodeReader()">
        開啟 QR Code 掃瞄器
      </SharedButton>
    </div>
  </div>
</template>

<script>
import liff from "@line/liff";
import { mapGetters } from "vuex";
import axios from "axios";
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";

export default {
  data() {
    return {
      result: "",
      error: "",
      displayLoading: false,
    };
  },
  components: { Loading, SharedButton },
  computed: {
    ...mapGetters("liffGeneral", ["liffId"]),
  },
  async mounted() {
    let _this = this;

    //this.displayLoading = true;

    await axios
      .get(
        process.env.VUE_APP_API_BASE_URL +
          "/" +
          this.$route.params.orgCode +
          "/liff/init"
      )
      .then((response) => {
        if (response.data.data.liff_id) {
          _this.liffId = response.data.data.liff_id;
          _this.initData = response.data.data;
        }
      })
      .catch(() => {
        _this.liffFailed();
      });

    const liffId = process.env.VUE_APP_LIFF_ID || _this.liffId;
    console.log(liffId);

    await liff.init({ liffId });

    if (!liff.isLoggedIn()) {
      liff.login({
        redirectUri: window.location.href,
      });
    } else {
      _this.token = liff.getAccessToken();

      await _this.getLiffInfo();
      await _this.getLiffMe();
      await _this.getLiffEvent();
    }

    this.displayLoading = false;
  },

  methods: {
    async openQRCodeReader() {
      liff
        .scanCodeV2()
        .then((result) => {
          this.result = result.value;
        })
        .catch((error) => {
          this.error = error;
          console.log("error", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 20px 10px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-secondary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border: 1px solid #fe0000;
    }
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }

  &__select-group {
    display: flex;
    > * {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
  }
}
</style>

